import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import styles from './politicaPrivacidad.module.scss'
import CookiesContents from '../static/cookies'
import { SeoData } from '../types/Seo'
import { useSelectedMenu } from '../../specific/helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../specific/constants/menu'

interface Props {
  data: {
    seoData: SeoData
  }
}
export default function Index({ data }: Props) {
  useSelectedMenu(MenuPrincipal.HOME)
  return (
    <Layout selector={false} seoData={data.seoData}>
      <CookiesContents styles={styles} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query cookiesQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
