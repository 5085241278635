import React from 'react'
import { locale } from '../../common/i18n'

interface Props {
  styles: { [k: string]: string }
}

const CookiesCa = ({ styles }: Props) => (
  <div className={styles.container}>
    <h1 className={styles.title}>Cookies</h1>
    <div className="aviso-cookiebot"></div>
  </div>
)

const CookiesEs = ({ styles }: Props) => (
  <div className={styles.container}>
    <h1 className={styles.title}>Información del uso de cookies</h1>
    <div className="aviso-cookiebot"></div>
  </div>
)

const CookiesPt = ({ styles }: Props) => (
  <div className={styles.container}>
    <h1 className={styles.title}>Informação do uso das cookies</h1>
    <div className="aviso-cookiebot">
      <div id="content-cookies">
        <h2>
            INFORMAÇÕES SOBRE A UTILIZAÇÃO DE COOKIES
        </h2>

        <b>O que são os cookies?</b>

        <p>
        Os cookies são pequenos arquivos de dados enviados ao computador pelo site que é visitado com o propósito de registar determinadas interações de navegação no site, armazenando dados que poderão ser atualizados e recuperados. Esses arquivos são armazenados no computador do utilizador e contêm dados anónimos que não são prejudiciais ao equipamento. São utilizados para lembrar as preferências do utilizador, como o idioma selecionado, dados de acesso ou personalização da página.<br></br>

        Os cookies também podem ser utilizados para registar informações anónimas sobre como um visitante utiliza um site. Por exemplo, desde qual página Web acedeu ou se utilizou um “banner” de publicidade para chegar lá.
        </p>


        <b>
            Que uso damos aos diferentes tipos de cookies?
        </b>


        <div className="container-fluid">
            <div className="row">
                De acordo com a sua finalidade:
            </div>
            <div className="row" style={{backgroundColor: '#DEE3E3'}}>
                <div className="col-12 col-lg-3 col-md-8 col-xs-12">
                    Cookies técnicos
                </div>
                <div className="col-12 col-lg-9 col-md-8 col-xs-12">
                    Os cookies técnicos são aqueles facilitam a navegação do utilizador e a utilização de diferentes opções ou serviços oferecidos pela Web, como identificar a sessão, permitir acesso a determinadas áreas, facilitar pedidos, comprar, preencher formulários, inscrever-se, segurança, facilitar funcionalidades (vídeos, redes sociais, etc.).
                </div>
            </div>

            <div className="row" style={{backgroundColor: 'white'}}>
                <div className="col-12 col-lg-3 col-md-8 col-xs-12">
                    Cookies de personalização
                </div>
                <div className="col-12 col-lg-9 col-md-8 col-xs-12">
                    Os cookies de personalização permitem ao utilizador aceder aos serviços de acordo com as suas preferências (idioma, navegador, configuração, etc.).
                </div>
            </div>

            <div className="row" style={{backgroundColor: '#DEE3E3'}}>
                <div className="col-12 col-lg-3 col-md-8 col-xs-12">
                    Cookies analíticos
                </div>
                <div className="col-12 col-lg-9 col-md-8 col-xs-12">
                    Os cookies de análise são aqueles que permitem a análise anónima do comportamento de utilizadores da Web e a medição das suas atividades, além da criação de perfis de navegação com o objetivo de melhorar os sites.
                </div>
            </div>

            <div className="row" style={{backgroundColor: 'white'}}>
                <div className="col-12 col-lg-3 col-md-8 col-xs-12">
                    Cookies de publicidade
                </div>
                <div className="col-12 col-lg-9 col-md-8 col-xs-12">
                    Os cookies de publicidade permitem a gestão dos espaços publicitários na Web. Além disso, os cookies de publicidade podem ser personalizados para permitir a gestão dos espaços publicitários na Web com base no comportamento e hábitos de navegação do utilizador. Uma vez é obtido o seu perfil, uma publicidade personalizada é exibida no navegador do utilizador ou em outros perfis e redes sociais do utilizador.
                </div>
            </div>

            <div className="row" style={{backgroundColor: '#DEE3E3'}}>
                <div className="col-12 col-lg-3 col-md-8 col-xs-12">
                    Cookies de sessão
                </div>
                <div className="col-12 col-lg-9 col-md-8 col-xs-12">
                    Os cookies de sessão são aqueles que duram enquanto o utilizador está navegando na página Web e são excluídos quando o navegador é fechado.</div>
            </div>

            <div className="row" style={{backgroundColor: 'white'}}>
                <div className="col-12 col-lg-3 col-md-8 col-xs-12">
                    Cookies persistentes
                </div>
                <div className="col-12 col-lg-9 col-md-8 col-xs-12">
                    Esses cookies são armazenados no terminal do utilizador até serem excluídos manualmente ou até que o período de duração estabelecido para o referido cookie tenha terminado.
                </div>
            </div>

        </div>
        <div className="container-fluid">
            <div className="row">
                De acordo com a sua propriedade::
            </div>
            <div className="row" style={{backgroundColor: '#DEE3E3'}}>
                <div className="col-12 col-lg-3 col-md-8 col-xs-12">
                    Cookies próprios
                </div>
                <div className="col-12 col-lg-9 col-md-8 col-xs-12">
                    São enviados para o terminal do utilizador a partir de um computador ou domínio gerido pelo próprio editor e do qual o serviço solicitado pelo utilizador é fornecido
                </div>
            </div>

            <div className="row" style={{backgroundColor: 'white'}}>
                <div className="col-12 col-lg-3 col-md-8 col-xs-12">
                    Cookies de terceiros
                </div>
                <div className="col-12 col-lg-9 col-md-8 col-xs-12">
                    S São enviados para o terminal do utilizador a partir de um computador ou domínio que não é gerido pelo editor, mas por outra entidade que processa os dados obtidos por meio de cookies.
                </div>
            </div>
        </div>
        <br></br>
        <div className="container-fluid">
            <div className="row">
                A tabela a seguir mostra a classificação e descrição dos cookies utilizados nesta página Web para que possa identificá-los no seu navegador:
            </div>
            <div className="row" style={{backgroundColor: '#fff2cc'}}>
                <div className="col-12 col-lg-2 col-md-2 col-xs-4">
                    Nome
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-xs-4">
                    Titular
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-xs-4">
                    Dados recolhidos
                </div>
                <div className="col-12 col-lg-3 col-md-3 col-xs-6">
                    Finalidade
                </div>
                <div className="col-12 col-lg-3 col-md-3 col-xs-6">
                    Duração
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-lg-2 col-md-2 col-xs-4" style={{backgroundColor: '#DEE3E3'}}>
                    _ga
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-xs-4">
                    Cookie próprio/Cookie de terceiros
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-xs-4">
                    Sessões de visitantes.
                </div>
                <div className="col-12 col-lg-3 col-md-3 col-xs-6">
                    Analítica.
                </div>
                <div className="col-12 col-lg-3 col-md-3 col-xs-6">
                    _2 anos desde o final da última visita
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-lg-2 col-md-2 col-xs-4" style={{backgroundColor: '#DEE3E3'}}>
                    _gid
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-xs-4">
                    Cookie próprio/Cookie de terceiros
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-xs-4">
                    Sessões de visitantes.
                </div>
                <div className="col-12 col-lg-3 col-md-3 col-xs-6">
                    Analítica.
                </div>
                <div className="col-12 col-lg-3 col-md-3 col-xs-6">
                    24 horas desde o final da última visita.
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-lg-2 col-md-2 col-xs-4" style={{backgroundColor: '#DEE3E3'}}>
                    _gac_UA
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-xs-4">
                    Cookie próprio/Cookie de terceiros
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-xs-4">
                    Sessões de visitantes.
                </div>
                <div className="col-12 col-lg-3 col-md-3 col-xs-6">
                    Analítica.
                </div>
                <div className="col-12 col-lg-3 col-md-3 col-xs-6">
                    90 dias.
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-lg-2 col-md-2 col-xs-4" style={{backgroundColor: '#DEE3E3'}}>
                    _fbp
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-xs-4">
                    Cookies de terceiros (Facebook)
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-xs-4">
                    Visitas a sites para remarketing.
                </div>
                <div className="col-12 col-lg-3 col-md-3 col-xs-6">
                    Publicidade.
                </div>
                <div className="col-12 col-lg-3 col-md-3 col-xs-6">
                    90 dias.
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-lg-2 col-md-2 col-xs-4" style={{backgroundColor: '#DEE3E3'}}>
                    _glc_au
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-xs-4">
                    Cookie próprio/Cookie de terceiros
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-xs-4">
                    Sessões de visitantes.
                </div>
                <div className="col-12 col-lg-3 col-md-3 col-xs-6">
                    Analítica.
                </div>
                <div className="col-12 col-lg-3 col-md-3 col-xs-6">
                    3 meses.
                </div>
            </div>


            <div className="row">
                <div className="col-12 col-lg-2 col-md-2 col-xs-4" style={{backgroundColor: '#DEE3E3'}}>
                    _glc_aw
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-xs-4">
                    Cookie próprio/Cookie de terceiros
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-xs-4">
                    Sessões de visitantes.
                </div>
                <div className="col-12 col-lg-3 col-md-3 col-xs-6">
                    Analítica.
                </div>
                <div className="col-12 col-lg-3 col-md-3 col-xs-6">
                    3 meses.
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-lg-2 col-md-2 col-xs-4" style={{backgroundColor: '#DEE3E3'}}>
                    _hjid
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-xs-4">
                    Cookie próprio/Cookie de terceiros
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-xs-4">
                    Sessões de visitantes.
                </div>
                <div className="col-12 col-lg-3 col-md-3 col-xs-6">
                    Analítica.
                </div>
                <div className="col-12 col-lg-3 col-md-3 col-xs-6">
                    1 ano.
                </div>
            </div>
        </div>

        <br></br>

        <b>
        A RODI utiliza cookies técnicos, de personalização, análise e publicidade próprios e de terceiros, que tratam dados de ligação e/ou do dispositivo, bem como hábitos de navegação para fins estatísticos e publicitários.
        </b><br></br>

        Portanto, ao aceder à nossa página Web, nos termos do artigo 22 da lei de serviços da sociedade da informação, solicitamos o seu consentimento para a utilização.<br></br>

        O fornecimento de dados pessoais através do nosso portal e o consentimento para a utilização de cookies <u>requer uma idade mínima de 14 anos</u> e a aceitação expressa da nossa Política de Privacidade.<br></br>

        Em qualquer caso, informamos-lhe que pode ativar ou desativar os cookies seguindo as instruções do seu navegador da Internet:




        <div className="container-fluid">
            <div className="row">
                A tabela a seguir mostra a classificação e descrição dos cookies utilizados nesta página Web para que possa identificá-los no seu navegador:
            </div>
            <div className="row" style={{backgroundColor: '#DEE3E3'}}>
                <div className="col-12 col-lg-3 col-md-2 col-xs-12">
                    Chrome
                </div>
                <div className="col-12 col-lg-6 col-md-8 col-xs-12">
                    Configuração → Mostrar opções avançadas → Privacidade → Configuração de conteúdo
                </div>
                <div className="col-12 col-lg-3 col-md-2 col-xs-12">
                    <a href="http://support.google.com" target="_blank">support.google.com</a>
                </div>
            </div>

            <div className="row" style={{backgroundColor: 'white'}}>
                <div className="col-12 col-lg-3 col-md-2 col-xs-12">
                    Firefox
                </div>
                <div className="col-12 col-lg-6 col-md-8 col-xs-12">
                    Ferramentas → Opções → Privacidade → Histórico → Configuração Personalizada.
                </div>
                <div className="col-12 col-lg-3 col-md-2 col-xs-12">
                    <a href="http://support.mozilla.org" target="_blank">support.mozilla.org</a>
                </div>
            </div>

            <div className="row" style={{backgroundColor: '#DEE3E3'}}>
                <div className="col-12 col-lg-3 col-md-2 col-xs-12">
                    Internet Explorer
                </div>
                <div className="col-12 col-lg-6 col-md-8 col-xs-12">
                    Ferramentas → Opções de Internet → Privacidade → Configuração.
                </div>
                <div className="col-12 col-lg-3 col-md-2 col-xs-12">
                    <a href="http://windows.microsoft.com" target="_blank">windows.microsoft.com</a>
                </div>
            </div>

            <div className="row" style={{backgroundColor: 'white'}}>
                <div className="col-12 col-lg-3 col-md-2 col-xs-12">
                    Opera
                </div>
                <div className="col-12 col-lg-6 col-md-8 col-xs-12">
                    Ferramentas → Opções de Internet → Privacidade → Configuração.
                </div>
                <div className="col-12 col-lg-3 col-md-2 col-xs-12">
                    <a href="http://windows.microsoft.com" target="_blank">windows.microsoft.com</a>
                </div>
            </div>


            <div className="row" style={{backgroundColor: '#DEE3E3'}}>
                <div className="col-12 col-lg-3 col-md-2 col-xs-12">
                    Safari
                </div>
                <div className="col-12 col-lg-6 col-md-8 col-xs-12">
                    Preferências → Segurança.
                </div>
                <div className="col-12 col-lg-3 col-md-2 col-xs-12">
                    <a href="http://www.apple.com" target="_blank">http://www.apple.com</a>
                </div>
            </div>

            <div className="row" style={{backgroundColor: 'white'}}>
                <div className="col-12 col-lg-3 col-md-2 col-xs-12">
                    Edge
                </div>
                <div className="col-12 col-lg-6 col-md-8 col-xs-12">
                    Configuração → Ver configuração avançada → Privacidade e serviços → Cookies.
                </div>
                <div className="col-12 col-lg-3 col-md-2 col-xs-12">
                    <a href="https://privacy.microsoft.com/es-es/windows-10-microsoft-edge-and-privacy" target="_blank">https://privacy.microsoft.com/es-es/windows-10-microsoft-edge-and-privacy</a>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
)

const CookiesContents = ({ ...props }: Props) => {
  if (locale === 'pt') {
    return <CookiesPt {...props} />
  } else if (locale === 'ca') {
    return <CookiesCa {...props} />
  } else {
    return <CookiesEs {...props} />
  }
}

export default CookiesContents
